import Vue from "vue";
import Options from "vue-class-component";
import AdminLayout from "@/layouts/Admin/index.vue";
import { Watch } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import UserService from "@/services/UserService";
import { IUser } from "@/models/IUser";
import Branch from "../Branches/Branch";
import { TheMask } from "vue-the-mask";
import BranchService from "@/services/BranchService";
import { IBranch } from "@/models/IBranch";

@Options({
  components: {
    AdminLayout,
    Multiselect,
    TheMask,
  },
})
export default class UserModule extends Vue {
  isloaded = false;
  loading = false;
  users: any = [];
  totalItems = 0;
  current_page = 1;
  selectedBranch: any = {};
  selectedBrachPermission: any = [];
  value: any = [];
  search = "";
  active = false;
  message = ""
  inputValid = true
  userType = [
    { UserType: "Administrator", UserTypeId: 1 },
    { UserType: "User", UserTypeId: 2 },
  ];
  branches: IBranch[] = [];
  branchesPrermission: any = [];
  selectedUserType: any = {};
  user: IUser = {
    userId: 0,
    userEmail: "",
    userPassword: "",
    userTypeId: 0,
    userName: "",
    userPhone: "",
    userAddress: "",
    isActive: false,
    createdUser: 0,
    branches: [],
  };

  table = {
    direction: "DESC",
    orderBy: "id",
    page: 0,
    size: 15,
    totalElements: 0,
  };

  async created() {
    var result = await UserService.getListofUser(1);

    if (result.items) {
      this.users = result.items;
      this.branches = await BranchService.getAllBranch();
      console.log(result.items);
      this.totalItems = result.totalPages;
      this.isloaded = true;
    }
  }

  handleChangePage(page) {
    this.table.page = page - 1;
  }

  async setTable(pagenumber: number) {
    this.users = [];

    var result = await UserService.getListofUser(1);

    if (result.items) {
      this.users = result.items;

      console.log(result.items);
      this.totalItems = result.totalPages;
      this.isloaded = true;
    }
  }

  @Watch("users")
  onPropertyChanged(value: string, oldValue: string) {
    this.isloaded = true;
  }

  @Watch("current_page")
  async onPropertyCurrentPageChanged(value: string, oldValue: string) {
    //this.isloaded = false
    await this.setTable(this.current_page);
    //console.log(this.current_page)
  }

  async handleSearch(searching) {
    // // if (searching.length > 3) {
    // //   this.search = searching;
    // //   var result = await ClientUserService.getListofClientUser(
    // //     this.topbranchId,
    // //     1,
    // //     this.search
    // //   );
    // //   this.users = result.items;
    // // } else if (searching.length == 0) {
    // //   var result = await ClientUserService.getListofClientUser(
    // //     this.topbranchId,
    // //     1,
    // //     ""
    // //   );
    // //   this.users = result.items;
    // }
  }

  onBack() {
    this.active = !this.active;
  }

  async onOpen() {
    this.active = !this.active;
    this.user = {
      userId: 0,
      userEmail: "",
      userPassword: "",
      userTypeId: 0,
      userName: "",
      userPhone: "",
      userAddress: "",
      isActive: false,
      createdUser: 0,
      branches: [],
    };
  }

  onStatusChange(status: boolean, userId: number) {
    (this as any).$Progress.start();
    UserService.updateUserStatus(userId, !status).then(
      (data) => {
        (this as any).$vs.notify({
          title: "User Status",
          text: data.message,
          color: "success",
          icon: "check_circle",
        });
        (this as any).$Progress.finish();
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "New User",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }

  async onEdit(userId: number) {
    this.user = await UserService.getUserByUserId(userId);
    let defaultBranchId = this.user.branches.find((x) => x.isDefault === true);

    this.selectedBranch = this.branches.find(
      (option) =>
        option.branchId ===
        this.user.branches.find((x) => x.isDefault === true)?.bracnhId
    );

    this.selectedUserType = this.userType.find(
      (x) => x.UserTypeId === this.user.userTypeId
    );
    this.onDefaultBranchSelect(defaultBranchId);

    let nonDefaultBranch = this.user.branches.filter(
      (x) => x.isDefault === false
    );

    for (var i = 0; i < nonDefaultBranch.length; i++) {
      let tag: IBranch = {
        branchId: nonDefaultBranch[i].bracnhId,
        branchName: this.branchesPrermission.find(
          (x) => x.branchId == nonDefaultBranch[i].bracnhId
        ).branchName,
        address: "",
        phoneNo: "",
        clientId: 0,
        userId: 0,
      };

      this.value.push(tag);
      // this.branchesPrermission.push(tag)
    }

    // await this.onChange(this.selectedBranch);
    // this.selectedPackage = this.clientPackages.find(
    //   (x) => x.clientPackageId == this.user.packageId
    // );
    this.active = !this.active;
  }

  onDefaultBranchSelect(data: any) {
    let branchId = 0;

    if (data.bracnhId == undefined) {
      branchId = data.branchId;
    } else {
      branchId = data.bracnhId;
    }
    this.branchesPrermission = this.branches.filter(
      (i) => i.branchId != branchId
    );
  }

  onSubmit() {
    this.user.branches.push({
      userBranchId: 0,
      userId: 0,
      bracnhId: this.selectedBranch.branchId,
      isDefault: true,
    });

    for (var i = 0; i < this.value.length; i++) {
      let b = {
        userBranchId: 0,
        userId: 0,
        bracnhId: this.value[i].branchId,
        isDefault: false,
        branchName: this.value[i].branchName,
      };
      this.user.branches.push(b);
    }

    this.user.userTypeId = this.selectedUserType.UserTypeId;

    UserService.saveUser(this.user).then(
      (data) => {
        if (data.statusCode == 200) {
          this.loading = false;
          this.setTable(1);
          (this as any).$vs.notify({
            title: "New User",
            text: data.message,
            color: "success",
            icon: "check_circle",
          });
          (this as any).$Progress.finish();
          if (this.user.userId == 0) {
            this.user = {
              userId: 0,
              userEmail: "",
              userPassword: "",
              userTypeId: 0,
              userName: "",
              userPhone: "",
              userAddress: "",
              isActive: false,
              createdUser: 0,
              branches: [],
            };
          }
        }
        else {
          this.loading = false;
          (this as any).$Progress.finish();
          this.message = data.message
          this.inputValid = true
        }
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "New User",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }
}
